import styled from 'styled-components'

export const Content = styled.div`
  padding: 0;
  text-align: center;

  h2 {
    width: 100%;
    font-size: 1.1rem;
    font-weight: 600;
    color: #404040;
  }

  hr {
    color: #979797;
    width: 90%;
    margin-top: 1.5rem;
  }

  p {
    width: 90%;
    color: #404040;
    font-weight: 400;
    font-size: 1rem;
    margin: 1.3rem auto 3rem;
  }

  img {
    width: 14rem;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1rem;

  button {
    width: 14rem;
    padding: 0.9rem 1rem;
  }
`
