import styled from 'styled-components'
import { Button } from '@components/Basic/ButtonNbe'
import { FormikInputField } from '@components/Formik/FormikInputField'

export const HeaderStyled = styled.div`
  line-height: 1.3;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1.5rem;
`

// Description below title
/* export const SubHeaderStyled = styled.div`
  font-size: 16px;
  margin-bottom: 2rem;
` */

export const ButtonGoBackStyled = styled(Button)`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4;
  background-color: transparent;
`

export const FooterStyled = styled.div`
  margin-top: 1.5rem;
  text-align: center;
`

export const InputFieldStyled = styled(FormikInputField)<{
  $isMatch?: boolean
}>`
  & > div:nth-of-type(1) {
    border: ${({ $isMatch }) =>
      $isMatch ? '1px solid #0c783a !important;' : ''};
  }
  input {
    padding: 0.625rem 1rem;
  }
`

export const InputWrapperStyled = styled.div`
  margin: 0.5rem 0 1rem 0;
`
