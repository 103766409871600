import styled, { css } from 'styled-components'

const separatorWidth = '50px'

export const SimpleBridgeBuilderWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 0;
  }
`

export const SimpleBridgeBuilderCardSeparator = styled.div`
  padding-top: 2rem;
  color: #c5c5c5;
  text-align: center;
  width: ${separatorWidth};
  @media (max-width: 767px) {
    width: 100%;
    padding-top: 0;
  }
`

export const getBridgeBuilderTypografy = () => css`
  h1,
  h2 {
    text-align: center;
    color: #191919;
  }

  h1 {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  h2 {
    font-size: 1rem;
  }

  /* synch this > subtitle */
  h4 {
    margin: 0 0 1rem;
    font-weight: 400;
    font-size: 0.8rem;
    text-align: center;
  }

  label {
    font-size: 0.8rem;
  }
`

export const SimpleBridgeBuilderCard = styled.div<{ hasError?: boolean }>`
  height: 100%;
  border: 1px solid
    ${({ hasError, theme }) =>
      hasError ? theme.input.error.borderColor : '#dfe4e8'};
  border-radius: 4px;
  padding: 1rem;
  position: relative;

  @media (min-width: 768px) {
    flex: 1;
    width: calc(50% - (${separatorWidth} / 2));
  }

  ${getBridgeBuilderTypografy};

  & > div {
    margin-bottom: 0.7rem;
  }
`
