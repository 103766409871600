import styled, { css } from 'styled-components'
import { ButtonProps } from './index'

const getVariant = ({ $variant }: ButtonProps) => {
  switch ($variant) {
    case 'primary':
      return css`
        background: #41a6dc;
        color: #fff;
        &:hover {
          opacity: 0.8;
        }
      `
    case 'action':
      return css`
        background: #fd9645;
        color: #fff;
        box-shadow: 1px 3px 6px 0 rgba(253, 150, 69, 0.3);
        &:hover {
          background: #eb7e28;
        }
      `

    case 'secondary':
      return css`
        background: #dfe4e8;
        color: #404040;
      `

    case 'blue':
      return css`
        background: #41a6dc;
        color: #fff;
      `

    case 'outlined-primary':
      return css`
        border: 1px solid #fd9645;
        background: none;
        color: #000;
      `

    case 'outlined-secondary':
      return css`
        border: 1px solid #dfe4e8;
        background: none;
        color: #000;
      `

    case 'link-primary':
      return css`
        background: none;
        color: ${({ theme }) => theme.color.primary};
      `

    case 'link-secondary':
      return css`
        background: none;
        color: #000;
      `

    case 'filter':
      return css`
        background: #3fb1f2;
        border-radius: 4px;
        color: #ffffff;
        padding: 0.45rem 1rem !important;
        &:hover {
          opacity: 0.8;
        }
      `
  }
}

const getSize = ({ $size }: ButtonProps) => {
  switch ($size) {
    case 'small':
      return css`
        padding: 0.45rem 1rem;
      `
    case 'standard':
      return css`
        padding: 0.75rem 1.4rem;
      `
    case 'large':
      return css`
        padding: 1rem 1rem;
        font-size: 16px;
      `
  }
}

export const ButtonStyled = styled.button<ButtonProps>`
  position: relative;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;
  letter-spacing: 0;
  text-align: center;
  border: none;
  font-weight: 700;

  ${({ $fluid }) =>
    $fluid
      ? css`
          width: 100%;
        `
      : null}

  ${({ $minWidth }) =>
    $minWidth
      ? css`
          width: 150px;
        `
      : null}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}


  ${({ $hasIcon }) =>
    $hasIcon &&
    css`
      display: inline-flex;
      align-items: center;
      svg {
        margin-right: 0.5rem;
      }
    `}
  
  ${getVariant}
  ${getSize}
`

// Following elements are just to handle the loading state of button
// using a bunch of elements that will be conditionally rendered
export const ButtonLoadingHiddenChildren = styled.span`
  opacity: 0;
  display: block;
`

export const ButtonLoadingWrapper = styled.span`
  display: flex;
  gap: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonLoadingSpinner = styled.span`
  display: block;
  width: 18px;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -0.64285714em 0 0 -0.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    border-radius: 500rem;
    border: 0.2em solid rgba(0, 0, 0, 0.15);
  }
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -0.64285714em 0 0 -0.64285714em;
    width: 1.28571429em;
    height: 1.28571429em;
    animation: button-loader-spin 0.6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0 0 0 1px transparent;
  }

  @keyframes button-loader-spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`
