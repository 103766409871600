import styled, { css } from 'styled-components'
import { mediaIframeOnly } from '@app/styles/iframeUtils'

interface TopbarStyledProps {
  hideOnEmbeddable?: boolean
  variant: 'light' | 'dark'
}

const hideWhenEmbedded = ({ hideOnEmbeddable }: TopbarStyledProps) =>
  hideOnEmbeddable
    ? css`
        ${mediaIframeOnly(css`
          display: none;
        `)};
      `
    : null

const getVariantColor = ({ variant }: TopbarStyledProps) =>
  variant === 'dark'
    ? css`
        background-color: #273649;
        color: white;
      `
    : css`
        background-color: #fff;
      `

export const TopbarStyled = styled.div``
export const TopbarWrapper = styled.div<TopbarStyledProps>`
  padding: 1.4rem 2.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;

  ${getVariantColor}
  ${hideWhenEmbedded}

  a {
    color: ${({ variant }) => (variant === 'dark' ? '#fff' : '#000')};
    text-decoration: none;
  }

  & > div {
    flex: 1;
    a {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    svg {
      margin-right: 5px;
      width: 18px;
      &:hover {
        color: ${({ variant, theme }) =>
          variant === 'dark'
            ? '#fff'
            : theme.button.primary.backgroundColorHover};
      }
    }
  }
  & div:nth-child(2) {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    input[disabled],
    .text-input-icon {
      ${({ variant }) =>
        variant === 'dark'
          ? css`
              color: white;
            `
          : null};
    }
  }
  & div:nth-child(3) {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    > * {
      margin: 0 15px;
    }
  }
`
