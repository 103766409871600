import styled, { css } from 'styled-components'
import backgroundPatternImage from '@assets/images/pattern-LB.svg'
import { mediaIframeOnly, mediaIframeUp } from '@app/styles/iframeUtils'

const embeddableMaxWidth = '801px'

export const PageEditorStyled = styled.div`
  @media (min-width: ${embeddableMaxWidth}) {
    /* background-color: rgba(65, 166, 220, 0.1); */
    position: relative;
    min-height: 100vh;
    //padding-bottom: 3em;

    &:before {
      content: '';
      background: url(${backgroundPatternImage}), #9f9f9f;
      opacity: 0.05;
      background-size: 250px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }
`
export const StepBarContainer = styled.div`
  display: none;

  // we target from 801 up
  ${mediaIframeUp(css`
    text-align: center;
    width: 100%;
    display: flex;
    margin: 2em auto;
    justify-content: center;
  `)};

  @media (max-height: 630px) {
    margin: 1em auto;
  }
`
export const PanelContainer = styled.div`
  width: 100%;
  min-width: 430px;
  margin: 0 auto;

  ${mediaIframeOnly(css`
    width: 100%;
  `)};

  // we target from 801 up
  ${mediaIframeUp(css`
    min-width: ${(props) => props.theme.container.medium};
    max-width: 1080px;
    padding: 0 2rem;
  `)};
`
export const PageTitle = styled.h2`
  display: none;
  @media (min-width: ${embeddableMaxWidth}) {
    display: block;
    font-weight: 600;
    padding-bottom: 2rem;
  }
`
