import styled, { css } from 'styled-components'

export const TestMapperWrapper = styled.div`
  width: 100%;
  padding: 1rem 2rem;
`

export const TestMapperField = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  padding: 1rem 0;
`

export const TestMapperFieldLabel = styled.label<{ isRequired?: boolean }>`
  position: relative;
  font-weight: 600;
  font-size: 0.9rem;
  display: inline-block;
  opacity: 0.75;

  ${({ isRequired }) =>
    isRequired
      ? css`
          &:after {
            content: '*';
            position: absolute;
            color: #137fc0;
            top: 0;
            left: 100%;
            font-size: 1.2rem;
          }
        `
      : null}
`
