import styled, { css } from 'styled-components'
import { mediaIframeOnly } from '@app/styles/iframeUtils'

export const PanelStyled = styled.div<{ extraPadding: boolean }>`
  /* we need button paddings when we have footer in the Panel */
  padding-bottom: ${({ extraPadding }) => (extraPadding ? '7rem' : '')};
  @media screen and (max-height: 740px) {
    padding-bottom: ${({ extraPadding }) => (extraPadding ? '90px' : '1em')};
  }
`
// ---------------- WRAPPER
export const PanelWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  ${mediaIframeOnly(css`
    box-shadow: unset;
  `)}
`

// ---------------- HEAD
export const PanelHead = styled.div`
  min-height: 60px;
  padding: 1.5rem 2rem;
  border-bottom: 2px solid whitesmoke;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow to bottom of panel head*/
`
// ---------------- BODY
interface PanelBodyProps {
  $hasExtraTopPadding?: boolean
  $panelHasHead?: boolean
}

export const PanelBody = styled.div<PanelBodyProps>`
  padding: 1.5rem 2rem;
`

export const PanelBodyInner = styled.div<{ paddingBottom?: number }>`
  min-height: 200px;
`

// ---------------- FOOTER
interface PanelFooterProps {
  $showTopSeparator: boolean
}
export const PanelFooter = styled.div`
  min-height: 60px;
  padding: 1.5rem 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255 255 255 / 60%);
  backdrop-filter: blur(5px);
  z-index: ${({ theme }) => theme.zIndex.panelFooter};
`
export const PanelFooterContainer = styled.div<PanelFooterProps>`
  min-width: 420px;
  max-width: 1080px;
  padding: 0 2rem;
  width: 100%;
  margin: 0 auto;
  position: relative;
  ${({ $showTopSeparator }) =>
    $showTopSeparator
      ? css`
          box-shadow: 0px -5px 10px 0px rgb(29 29 29 / 5%);
        `
      : null};
`
export const PanelFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const PanelFooterLeft = styled.div``
export const PanelFooterRight = styled.div`
  text-align: right;
`
