import styled, { css } from 'styled-components'
import { LabelProps } from '@components/Form/Label/index'

export const LabelStyled = styled.label<LabelProps>`
  text-align: left;
  color: rgb(99, 115, 129);
  font-weight: 600;
  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: 0.5;
        `
      : null}
`
