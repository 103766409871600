import styled from 'styled-components'

export const PopupInstanceDetector = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 2rem 2rem 0;
  color: #fff;
  border-radius: 0.4rem;
  z-index: 1;
`
