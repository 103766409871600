import { ProgressProps } from './index'
import styled from 'styled-components'

const theme: Record<ProgressProps['$theme'], string> = {
  primary: '#22a7f0',
}

const getProgressWidth = ({ $progress }: ProgressProps) => {
  return `${$progress < 0 ? 0 : $progress > 100 ? 100 : $progress}%`
}

const getBgColor = ({ $theme }: ProgressProps) => theme[$theme]

export const ProgressDivStyled = styled.div<ProgressProps>`
  display: block;
  width: 100%;
  position: relative;
  height: 0.4rem;
  background: #fff;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${getProgressWidth};
    background-color: ${getBgColor};
    transition: width 0.4s ease-out;
  }
`
