import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  background: rgba(217, 222, 225, 0.15);
  margin: 4rem 0;
  gap: 2rem;

  & > div {
    flex: 1;
  }

  ul {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 18px;

    li {
      margin-bottom: 0.5rem;
    }
  }
`

export const PlanType = styled.p`
  text-transform: uppercase;
  font-size: smaller;
  letter-spacing: 0.7px;
  font-weight: 600;
  color: #41a6dc;
  margin: 0 0 0.5rem 0;
`

export const PlanName = styled.h2`
  font-size: 1.7rem;
  color: #1f1f1f;
  margin: 0;
`

export const PriceStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;

  span {
    opacity: 0.5;
    margin-right: 2rem;
    margin-bottom: 1.5rem;
    position: absolute;
    font-size: large;
    top: -5px;
    left: -15px;
  }

  h2 {
    position: relative;
    margin: 0 0 10px 0;
    font-weight: bold;
  }
`
