import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  text-align: center;
`
export const IconWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 2;

  span {
    flex: 1;
    border-bottom: 3px dashed #fff;
  }
`

export const ImgWrapperStyled = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`

export const ImgBoxStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 0.8rem 0.8rem;
  width: 9rem;
  height: 8rem;

  img {
    width: 3rem;
    height: 3rem;
    margin-bottom: 0.5rem;
    object-fit: contain;
  }
`

export const HeaderTextStyled = styled.p`
  font-size: 1.7rem;
  margin-bottom: 1.6rem;
  color: #fff;
  font-weight: 700;
`
