import styled, { css } from 'styled-components'
import { MessageProps } from '@components/Basic/Message/index'

const getStatus = ({ $status }: MessageProps) => {
  switch ($status) {
    case 'success':
      return css`
        background-color: #fcfff5;
        box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent;
        color: #2c662d;
      `
    case 'error':
      return css`
        background-color: #fff6f6;
        box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
        color: #9f3a38;
      `
  }
}

export const MessageStyled = styled.div<MessageProps>`
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  background-color: #f8f8f9;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  box-shadow: 0 0 0 1px rgb(34 36 38 / 22%) inset, 0 0 0 0 transparent;
  font-size: 1.14em;
  color: rgba(0, 0, 0, 0.85);

  .message-body {
    margin: 0;
  }

  .message-title {
    font-weight: 700;
  }

  ${getStatus}
`
