import styled, { css } from 'styled-components'
import { mediaIframeOnly } from '@app/styles/iframeUtils'

export const StatusBarBridgeWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 600;
  padding: 0.5rem 2.5rem;
  min-height: 40px;
  @media (max-width: 700px) {
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
  }
`
export const StatusBarStatus = styled.div`
  text-transform: uppercase;
  padding-right: 1rem;
  white-space: nowrap;
  margin-right: 1rem;
  @media (min-width: 700px) {
    border-right: 1px solid white;
  }
`
export const StatusBarMessage = styled.div`
  margin-right: 1rem;
  @media (max-width: 700px) {
    text-align: center;
  }
  ${mediaIframeOnly(css`
    font-size: 0.8rem;
  `)};
`
export const BarCenter = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`
export const BarLeft = styled.div``
export const BarRight = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  button {
    white-space: nowrap;
  }
`
export const StatusBarBridgeStyled = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.button.primary.backgroundColor};
  color: white;
`

export const CloseIcon = styled.div`
  position: absolute;
  cursor: pointer;
  top: 6px;
  right: 8px;
`
