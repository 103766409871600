import styled from 'styled-components'

export const IconsWrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

export const IconButtonStyled = styled.button`
  background-color: #dfe4e8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  :hover {
    background-color: #ced6d7ff;
  }
`
