import styled, { css, keyframes } from 'styled-components'
import { StylesConfig } from 'react-select'
import { SelectValue, SmartSelectProps } from './index'
import { defaultTheme } from '@app/styles/theme/default'

// Custom style to be used as css override, without the need of creating custom react components
export const customStyles: StylesConfig<SelectValue> = {
  menuList: (style, { selectProps }) => {
    const isFormulas = (selectProps as any).isFormulas
    return {
      ...style,
      padding: '0',
      maxHeight: isFormulas ? '300px' : '200px',
      overflowX: 'hidden',
      overflowY: 'auto',
    }
  },
  placeholder: (style, { selectProps }) => {
    const addActionClick = (selectProps as any)
      .addActionClick as SmartSelectProps['addActionClick']
    return {
      ...style,
      color: addActionClick?.label ? '' : 'hsl(0, 0%, 50%)',
    }
  },
  option: () => ({
    // empty so style is reset
  }),
  menu: (style) => ({
    ...style,
    boxShadow: 'none',
    marginTop: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 200,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: '4px',
    top: '100%',
    background: '#fff',
    border: '1px solid #137FC0',
    fontFamily: defaultTheme.font.family,
  }),
  container: (style) => ({
    ...style,
    position: 'relative',
    width: '100%',
    fontSize: '0.9rem',
    pointerEvents: 'initial', // we need this sho we can show tooltip on disabled elements
  }),
  control: (style, { isDisabled }) => ({
    ...style,
    // emulating InputField style
    minHeight: '43.11px',
    border: '1px solid rgba(34,36,38,0.15)',
    cursor: isDisabled ? 'default' : 'pointer',
    backgroundColor: isDisabled ? 'rgba(34, 36, 38, 0.03)' : 'white',
    color: isDisabled ? 'rgb(170, 170, 170)' : 'rgb(67, 73, 78)',
    fontFamily: defaultTheme.font.family,
  }),
  input: (style, { selectProps }) => {
    // in case of value with image (logoUri) we want to have the input indicator
    // starting from item name and not at the logo position
    const hasValueWithLogo = (selectProps.value as SelectValue | undefined)
      ?.logoUri
    if (hasValueWithLogo) {
      return {
        ...style,
        position: 'relative',
        left: '23px',
      }
    }
    return {
      ...style,
    }
  },
}

export const greyStyles: StylesConfig<SelectValue> = {
  menuList: (style) => ({
    ...style,
    padding: '0',
    maxHeight: '200px',
    overflowX: 'hidden',
    overflowY: 'auto',
  }),
  option: () => ({
    // empty so style is reset
  }),
  menu: (style) => ({
    ...style,
    boxShadow: 'none',
    marginTop: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 1000,
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: '4px',
    top: '100%',
    background: '#EDEDEE',
    border: '1px solid #137FC0',
    fontFamily: defaultTheme.font.family,
  }),
  container: (style) => ({
    ...style,
    position: 'relative',
    width: '100%',
    fontSize: '0.9rem',
    pointerEvents: 'initial', // we need this sho we can show tooltip on disabled elements
  }),
  control: (style, { isDisabled }) => ({
    ...style,
    // emulating InputField style
    minHeight: '39.16px',
    border: '1px solid rgba(34,36,38,0.15)',
    cursor: isDisabled ? 'default' : 'pointer',
    backgroundColor: '#EDEDEE',
    color: isDisabled ? 'rgb(170, 170, 170)' : 'rgb(67, 73, 78)',
    fontFamily: defaultTheme.font.family,
  }),
  input: (style, { selectProps }) => {
    // in case of value with image (logoUri) we want to have the input indicator
    // starting from item name and not at the logo position
    const hasValueWithLogo = (selectProps.value as SelectValue | undefined)
      ?.logoUri
    if (hasValueWithLogo) {
      return {
        ...style,
        position: 'relative',
        left: '23px',
      }
    }
    return {
      ...style,
    }
  },
}

export const SmartSelectTooltipInfoMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: rgba(255, 255, 2555, 0.1);
  color: whitesmoke;
  padding: 5px 10px;
`

export const SmartSelectWrapper = styled.div<{ isLoading?: boolean }>`
  ${({ isLoading }) =>
    isLoading
      ? css`
          pointer-events: none;
          touch-action: none;
          user-select: none;
        `
      : null}

  .react-select-tooltip {
    font-size: 0.8rem;
    border-radius: 4px;
    max-width: 430px;
    padding: 0;
    label {
      padding: 0 0.5rem;
      display: block;
      margin-bottom: 5px;
    }
  }
`

// Custom Option component
interface StyledOptionWrapperProps {
  hasPadding?: boolean
  hasHoverEffect?: boolean
  isFocused?: boolean
  isSelected?: boolean
}

export const StyledOptionWrapper = styled.div<StyledOptionWrapperProps>`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: ${({ hasPadding }) => (hasPadding ? '0.5rem 1rem' : '0')};
  cursor: pointer;
  grid-area: 1/1/2/3;

  border-width: 1px;
  border-style: dotted;
  border-color: ${({ isFocused }) => (isFocused ? '#979797' : 'transparent')};
  background-color: ${({ isFocused }) =>
    isFocused ? 'rgba(238, 238, 238, 0.4)' : 'transparent'};

  &:not(:first-of-type):before {
    content: '';
    position: absolute;
    top: 0;
    left: 1rem;
    right: 1rem;
    height: 1px;
    border-top: 1px solid #979797;
  }

  &:hover {
    ${({ hasHoverEffect }) =>
      hasHoverEffect
        ? css`
            background-color: #efefef;
          `
        : null}
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          &,
          &:hover {
            background-color: #41a6dc;
            color: #fff;
            font-weight: 600;
          }
        `
      : null}
`

export const StyledOptionImageWrapper = styled.div`
  width: 1.3rem;
  height: auto;
  margin-right: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const StyledOptionValueWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
`

export const StyledOptionWithExtraLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span:nth-child(2) {
    opacity: 0.4;
    font-size: small;
    padding-left: 10px;
  }
`
export const StyledOptionText = styled.div<{
  $variant?: 'light' | 'default'
  emailValidation?: boolean
}>`
  font-size: 0.9rem;
  color: ${({ $variant }) => ($variant === 'light' ? '#696f8c' : 'inherit')};

  svg {
    color: #696f8c;
    width: ${({ emailValidation }) => (emailValidation ? 'inherit' : '0.7rem')};
    height: ${({ emailValidation }) =>
      emailValidation ? 'inherit' : '0.7rem'};
    margin-left: 0.5rem;
  }
`

export const StyledOptionSecondaryLabel = styled.div`
  font-size: 0.9rem;
`

export const StyledControlIconWrapper = styled.div`
  display: flex;
  padding-left: 0.5rem;
`

export const AddActionClickButton = styled.div`
  appearance: none;
  background: #fff;
  border: 0;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
`

export const AddNewSelectOptionButton = styled.button`
  /* reset button style */
  appearance: none;
  background: #fff;
  border: 0;

  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;

  svg {
    size: 0.9em;
    margin-right: 0.5rem;
  }

  &:hover {
    background-color: #efefef;
  }
`

export const VerifyContentWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  //margin-left: 0.5rem;
  vertical-align: middle;
  justify-content: right;

  svg {
    margin-right: 0.3rem;
  }

  p {
    font-size: 0.8rem;
  }
`

export const FloatingLabelStyled = styled.label<{ isFloating?: boolean }>`
  position: absolute;
  display: block;
  top: ${(props) => (props.isFloating ? `1px` : `50%`)};
  left: 10px;
  font-weight: 300;
  font-size: ${(props) => (props.isFloating ? `.75rem` : `.9rem`)};
  transform: ${(props) => (props.isFloating ? `none` : `translateY(-50%)`)};
  transition: 0.4s ease all;
`

export const DropDownIconsWrapperStyled = styled.div<{ isMenuOpen: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60%;
  border-left: 2px solid
    ${({ isMenuOpen }) => (isMenuOpen ? '#3fb1f2' : '#c3ccd5')};
  padding-left: 0.5rem;
`

export const InputWrapperStyled = styled.div<{ isEmailValidation: boolean }>`
  display: ${({ isEmailValidation }) => (isEmailValidation ? 'flex' : 'block')};
  align-items: center;
  justify-content: space-between;
  div {
    flex: 1;
  }
`
