import styled from 'styled-components'
import { getBridgeBuilderTypografy } from '@features/nbee/SimpleBridgeBuilderForm/styled'

export const ModalAddOptionCard = styled.div`
  ${getBridgeBuilderTypografy};

  padding: 1rem 2rem;

  & > div {
    margin-bottom: 1rem;
  }
`

export const ModalAddOptionErrorMessage = styled.div`
  font-size: 0.8rem;
  line-height: 1.1rem;
  a {
    word-break: break-all;
  }
`
