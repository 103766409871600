import styled, { css } from 'styled-components'

export const PanelImageStyled = styled.img`
  margin: 1em auto;
  max-width: 100%;
`
export const PanelBodyWrapper = styled.div<{ isPopupOpen: boolean }>`
  ${({ isPopupOpen }) =>
    isPopupOpen
      ? css`
          position: relative;

          &:after {
            content: '';
            background-color: rgb(0 0 0 / 75%);
            border-radius: 5px;
            top: -40px;
            left: -40px;
            right: -40px;
            bottom: -40px;
            position: absolute;
          }
        `
      : null}
`
export const PanelBodyStyled = styled.div`
  text-align: center;
`

export const PanelBodyInnerStyled = styled.div`
  text-align: center;
  width: 500px;
  margin: 0 auto 1rem;
`

export const IntegrationLogo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  flex-direction: column;
  gap: 10px;
  label {
    font-size: small;
  }
`
