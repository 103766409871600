import styled from 'styled-components'

export const StepBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  //TO REMOVE when we have grid system
  max-width: 1080px;
  min-width: 350px;
  width: 100%;

  div:nth-child(1) .step-bar-left {
    border: 0;
  }
  div:last-child .step-bar-right {
    border: 0;
  }
`

export const StepBarItemStyled = styled.div`
  & {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    flex: 1;
  }
  .step-bar-circle {
    border-radius: 100%;
    background-color: white;
    border: 1px solid #273649;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: default;
  }
  .step-bar-label {
    margin-top: 5px;
    font-weight: 600;
    font-size: 0.9rem;
    color: black;
  }
  .is-clickable {
    cursor: pointer;
  }
  .step-bar-optional {
    opacity: 0.6;
  }

  .step-bar-right,
  .step-bar-left {
    position: absolute;
    top: 20px;
    height: 1px;
    border-top: 1px solid #273649;
  }
  .step-bar-right {
    right: 0;
    left: 50%;
    margin-left: 20px;
  }

  .step-bar-left {
    left: 0;
    right: 50%;
    margin-right: 20px;
  }

  &.active {
    .step-bar-circle {
      background-color: #273649;
      color: white;
    }
  }
  span[title] {
    cursor: default;
  }
  span[title].is-clickable {
    cursor: pointer;
  }
`
