import styled from 'styled-components'

export const CheckboxStyled = styled.div`
  margin-bottom: 0.5em;

  &.error {
    color: red;
  }

  &.disabled {
    opacity: 0.4;
    .container,
    .container:hover input ~ .checkmark,
    .checkmark {
      cursor: not-allowed;
      animation: unset;
      border-color: dimgrey;
    }
  }
  .container {
    display: block;
    position: relative;
    padding: 2px 0px 2px 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 500;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid dimgrey;
    border-radius: 3px;
    transition: all 0.16s ease-in;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    border: 2px solid black;
    transition: all 0.16s ease-out;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196f3;
    border-color: #2196f3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
