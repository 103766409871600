import styled, { css } from 'styled-components'
import { StripeCardNumberElementOptions } from '@stripe/stripe-js'

export const stripeElementOptions: StripeCardNumberElementOptions = {
  style: {
    base: {
      lineHeight: '40px',
      fontSize: '16px',
      color: '#000',
    },
  },
}

export const CardFieldsWrapper = styled.div<{ isLoading?: boolean }>`
  position: relative;
  max-width: 420px;
  opacity: 1;
  transition: all 0.16s ease-out;
  ${({ isLoading }) =>
    isLoading
      ? css`
          transition: all 0.16s ease-in;
          opacity: 0;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
          }
        `
      : null}
`

export const CreditCardRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  > * {
    flex: 1;
  }
`

export const CardItemNumberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  .card-provider {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 0.6;
    width: 30px;
    margin-right: 10px;
  }
  .card-item-number {
    width: 100%;
    margin-top: 1px;
  }
`
export const CardBoxInputGroup = styled.div<{ hasFocus?: boolean }>`
  .card-item {
    background-color: #ededee;
    height: 50px;
    border-radius: 0.4rem;
    padding: 5px 1rem;

    ${({ hasFocus }) =>
      hasFocus
        ? css`
            box-shadow: 0 0 3px 1px #41a6dc;
          `
        : null}
    &.isLoading {
      opacity: 0;
    }
  }
`
export const CardFieldsContainer = styled.div`
  position: relative;
  .loader-container-form {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
`
