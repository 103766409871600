import styled from 'styled-components'

export const IntegrationLogoWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 2em;
`
export const AccountCreationFormWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 2em;
  padding: 2em 5em;
  & > div {
    display: flex;
    gap: 2em;
    width: 100%;
  }
`
export const AccountCreationInputGroup = styled.div`
  > div {
    position: relative;
    flex: 1;
  }
`
