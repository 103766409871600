import styled from 'styled-components'

export const ContentWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 540px;
  width: 100%;
  text-align: center;
  img {
    max-width: 100%;
  }
`

export const HeaderTextStyled = styled.p`
  font-size: 1.7rem;
  color: #fff;
  margin: 0;
  font-weight: 700;
`

export const SubTextStyled = styled.p`
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
`
