import styled from 'styled-components'
import { MdDelete } from 'react-icons/md'
import { FakeInput } from '@components/Basic/FakeInput'

interface RowStyledProps {
  showWelcomeMessage?: boolean
}

interface DeleteIconProps {
  visible: string
}

export const RowWrapper = styled.div<RowStyledProps>`
  padding: 1rem 0;
  & + & {
    ${(props) => !props.showWelcomeMessage && 'border-top: 1px solid #b7b7b7;'}
  }
`

export const RowStyled = styled.div<RowStyledProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 2.5rem;
`

// used for error displaying
export const RowFeedback = styled.div<RowStyledProps>`
  ${(props) =>
    props.showWelcomeMessage ? 'display: block;' : 'grid-column: 3;'}
  p {
    font-size: 0.8rem;
  }
`

export const BinIconWrapper = styled.div`
  min-width: 1.8rem;
  margin-left: 0;
`

export const DeleteIcon = styled(MdDelete)<DeleteIconProps>`
  color: #db3c3c;
  height: 20px;
  width: 20px;
  cursor: pointer;
  visibility: ${(props) =>
    props.visible === 'visible' ? 'visible' : 'hidden'};
`

// Important: the MultiCreatableCustom component has a fixed width
export const SourceFieldsWrapper = styled.div<{ fullWidth?: boolean }>`
  min-width: ${(props) => (props.fullWidth ? '100%' : '600px')};
`

export const DestinationFieldsWrapper = styled.div`
  width: 100%;
  font-size: 0.9rem;
  display: flex;
`

export const StateIconErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  margin: 0 0.4rem;
  padding: 0;
`

export const ErrorWrapper = styled.span`
  height: 0;
  white-space: nowrap;
`
export const DestinationFieldLabel = styled.span`
  // background-color: blue;
  display: flex;
  p {
    width: 200px;
    overflow-wrap: break-word;
  }
`
