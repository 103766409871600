import styled from 'styled-components'

export const CheckoutPrePrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  color: #4d4f52;
`

export const CheckoutTrialLimitsDisclaimer = styled.div`
  color: #4d4f52;
`

export const CeckoutTotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: #363636;
    font-weight: 600;
    font-size: 1.3rem;
  }
`
