import styled, { css } from 'styled-components'

export const DropdownWrapper = styled.div`
  position: relative;
  width: auto;
  display: inline-block;
`

// The line separator is applied to each of the items: "each" props is by default props.
export type DropdownLineSeparatorMenuItem = 'last' | 'none'
export type DropdownMenuDirection = 'left' | 'right'
interface DropdownMenuStyledProps {
  $isOpen: boolean
  $direction: DropdownMenuDirection
  $lineSeparatorMenuItem?: DropdownLineSeparatorMenuItem
}

const getDropdownlineSeparatorChild = ({
  $lineSeparatorMenuItem,
}: DropdownMenuStyledProps) =>
  $lineSeparatorMenuItem === 'none'
    ? css`
        & > div {
          border: 0;
        }
      `
    : $lineSeparatorMenuItem === 'last'
    ? css`
        & > div:nth-last-child(1) {
          border-top: 1px solid #d4d3d3;
        }
      `
    : css`
        /* adding seprator line between elements */
        & + & {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            border-top: 1px solid #d4d3d3;
            left: 0.7rem;
            right: 0.7rem;
          }
        }
      `

const getSideDirection = ({ $direction }: DropdownMenuStyledProps) =>
  $direction === 'left'
    ? css`
        left: 0;
      `
    : css`
        right: 0;
      `

export const DropdownMenuStyled = styled.div<DropdownMenuStyledProps>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  position: absolute;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  min-width: 230px;
  max-width: 60vw; /* just in case elements are too wide  */
  max-height: 300px; /* content can be scrolled  */
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 0.3rem;
  border: 1px solid #41a6dc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 3px 6px 0 rgba(25, 25, 25, 0.2);
  text-align: left;
  ${getSideDirection};
  ${getDropdownlineSeparatorChild}
`

interface DropdownItemStyledProps {
  isActive?: boolean
  isDisabled?: boolean
}
const getDropdownActive = ({ isActive, isDisabled }: DropdownItemStyledProps) =>
  isActive
    ? css`
        cursor: default;
        pointer-events: none;
        background-color: ghostwhite;
        svg {
          opacity: 1 !important;
          color: ${({ theme }) => theme.button.primary.backgroundColor};
        }
      `
    : isDisabled
    ? css`
        opacity: 0.8;
        pointer-events: none;
        svg {
          opacity: 0.5 !important;
        }
      `
    : css``

export const DropdownMenuItemLabel = styled.div`
  font-size: smaller;
  color: ${({ theme }) => theme.button.primary.backgroundColor};
`
export const DropdownItemStyled = styled.div<DropdownItemStyledProps>`
  display: flex;
  align-items: center;
  padding: 0.4rem 0.7rem;
  position: relative;
  cursor: pointer;
  font-size: 0.82rem;
  white-space: nowrap; /* keeps text on same line and will make menu wider  */
  ${getDropdownActive}
  &:hover {
    background-color: #eef4f7;
  }
  /* some items can contains svg icon */
  svg {
    opacity: 0.6;
    margin-right: 10px;
    // height: 1rem;
    // font-size: small;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`

export const DropdownIconWrapper = styled.div`
  margin-left: 0.5rem;
  font-family: sans-serif;

  svg {
    margin: 0;
    opacity: 1 !important;
  }
`
