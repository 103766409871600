import styled, { css } from 'styled-components'
import { Props } from './index'

export const SignupFormWrapper = styled.div<Pick<Props, 'isAppSumo'>>`
  max-width: 540px;
  border-radius: 9px;
  background-color: #fff;
  position: relative;
  padding: ${({ isAppSumo }) => (isAppSumo ? '0 2rem 3rem' : '3rem 2rem')};
  min-height: 590px;

  @media (min-width: 768px) {
    padding: ${({ isAppSumo }) => (isAppSumo ? '0 4rem 1rem' : '1rem 4rem')};
    height: ${({ isAppSumo }) => (isAppSumo ? 'auto' : '85vh')};
    max-height: ${({ isAppSumo }) => (isAppSumo ? 'auto' : '700px')};
    display: flex;
    align-items: center;
  }
  @media (min-width: 1070px) {
    padding: 1rem 5rem;
    padding: ${({ isAppSumo }) => (isAppSumo ? '0 5rem 1rem' : '1rem 5rem')};
  }

  label {
    color: #273649;
    font-weight: 600;
    font-size: 1rem;
  }

  input,
  select {
    background-color: #f1f1f1 !important;
    border-radius: 0.375rem;
    &::placeholder {
      color: #77818d;
    }
  }
`

export const FormRow = styled.div`
  display: flex;
  gap: 0.9rem;
  align-items: flex-start;
  @media (min-height: 740px) {
    margin-bottom: 1rem;
  }
`

export const TitleStyled = styled.p`
  margin-bottom: 1rem;
  @media (min-height: 740px) {
    margin-bottom: 2.4rem;
  }
  font-weight: 600;
  font-size: 1.7rem;
  color: #273649;
  text-align: center;
`

export const HeaderTop = styled.div`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  &::after {
    position: absolute;
    margin-top: 0.5rem;
    left: 0;
    content: ' ';
    display: block;
    height: 40px;
    border-bottom: 1px solid #dfe4e8;
    width: 100%;
  }
`

export const ContentWrapper = styled.div<Pick<Props, 'isAppSumo'>>`
  ${({ isAppSumo }) =>
    isAppSumo
      ? css`
          height: 100%;
        `
      : ''}
`

export const SocialProvidersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const SeparatorStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  @media (min-height: 740px) {
    padding: 1.4rem 0;
  }

  hr {
    flex: 1;
  }
  span {
    padding: 0 1rem;
  }
`

export const FooterStyled = styled.div<Pick<Props, 'isAppSumo'>>`
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
  justify-content: space-between;
  margin-top: ${({ isAppSumo }) => (isAppSumo ? '0' : '1rem')};

  flex-wrap: wrap;
  gap: 1rem;
  @media (min-width: 768px) {
    gap: 0;
    flex-wrap: nowrap;
  }
`

export const TermsText = styled.p`
  margin: 0;
  padding: 0.2rem 0 0;
  font-size: 12px;

  a {
    color: #41a6dc !important;
    &:hover {
      text-decoration: underline;
    }
  }
`
