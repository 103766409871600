import styled, { css } from 'styled-components'
import { SelectFieldProps } from './index'

const getInputDirectionStyle = ({ $direction }: SelectFieldProps) =>
  $direction === 'column'
    ? css`
        flex-direction: column;
        label {
          margin-bottom: 5px;
        }
      `
    : css`
        flex-direction: row;
        align-items: center;
        label {
          padding-right: 0.5rem;
        }
      `

const getStatusStatusStyle = ({ $status }: SelectFieldProps) => {
  if ($status?.error) {
    return css`
      background-color: ${({ theme }) =>
        theme.input.error.backgroundColor} !important;
      border: 1px solid ${({ theme }) => theme.input.error.borderColor} !important;
    `
  }

  if ($status?.success) {
    return css`
      background-color: ${({ theme }) =>
        theme.input.success.backgroundColor} !important;
      border: 1px solid ${({ theme }) => theme.input.success.borderColor} !important;
    `
  }

  return css`
    background-color: ${({ theme }) => theme.input.default.backgroundColor};
    border: 1px solid ${({ theme }) => theme.input.default.borderColor};
  `
}

export const SelectStyled = styled.div<SelectFieldProps>`
  width: 100%;
  line-height: 19px;
  position: relative;
  margin: ${({ $hasMargin }) => ($hasMargin ? '0.5rem 0 1rem 0' : '0')};
  font-size: 1rem;
  display: flex;
  font-family: ${({ theme }) => theme.font.family};
  ${getInputDirectionStyle}

  select {
    width: 100%;
    line-height: normal;
    padding: 0.555em 1em;
    font-size: 0.938rem;
    outline: 0;
    text-align: left;
    color: rgb(67 73 78);
    border-radius: 0.28571429rem;
    appearance: none;
    font-family: ${({ theme }) => theme.font.family};

    ${getStatusStatusStyle};

    &:disabled {
      background: ${({ theme }) => theme.input.disabled.background};
      color: ${({ theme }) => theme.input.disabled.color};
      opacity: 1;
      cursor: default;

      ~ div > svg {
        /* hide icon when disabled  */
        display: none;
      }
    }
  }

  svg {
    height: 1.3rem;
    margin: 0.5rem;
  }
`

export const SelectWrapper = styled.div`
  position: relative;

  div {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: 100%;
    pointer-events: none;
  }

  svg {
    display: block;
  }
`
