import styled, { css } from 'styled-components'
import { InputFeedbackStatus } from '@components/Basic/InputFeedback'

export const TextareaWrapper = styled.div`
  width: 100%;

  label {
    display: block;
    margin-bottom: 5px;
  }
`

export interface TextareaStyledProps {
  $status?: InputFeedbackStatus
}

const getTextareaStatusStyle = ({
  $status,
}: {
  $status?: InputFeedbackStatus
}) => {
  if ($status?.success) {
    return css`
      background-color: ${({ theme }) => theme.input.success.backgroundColor};
      border: 1px solid ${({ theme }) => theme.input.success.borderColor};
      input {
        background-color: transparent;
      }
    `
  }

  if ($status?.error) {
    return css`
      background-color: ${({ theme }) => theme.input.error.backgroundColor};
      border: 1px solid ${({ theme }) => theme.input.error.borderColor};
      input {
        background-color: transparent;
      }
    `
  }

  return css`
    background-color: ${({ theme }) => theme.input.default.backgroundColor};
    border: 1px solid ${({ theme }) => theme.input.default.borderColor};
    &:disabled {
      background: ${({ theme }) => theme.input.disabled.background};
      color: ${({ theme }) => theme.input.disabled.color};
  `
}

export const TextareaStyled = styled.textarea<TextareaStyledProps>`
  display: block;
  width: 100%;
  min-height: 120px;
  border-radius: 0.3rem;
  padding: 0.5rem;
  ${getTextareaStatusStyle}
`
