import styled from 'styled-components'

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 1.5rem;
  label {
    font-size: 0.875rem;
    color: #787a7d;
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  a {
    font-size: 0.8rem;
    margin: 0;
  }
`

export const InputField = styled.div`
  position: relative;
  width: 100%;
  border-radius: 6px;
  border: 1px solid rgba(65, 166, 220, 0.3);
  display: flex;
  margin-top: 0.3rem;

  & svg {
    cursor: pointer;
  }

  & input {
    cursor: pointer;
    font-size: 0.875rem;
    color: #137fc0;
    padding: 1rem 7rem 1rem 0.5rem;
    border: none;
    border-radius: 6px;
    width: 100%;
    outline: none;
    background-color: rgba(237, 246, 251, 0.3);
    text-decoration: underline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    &::selection {
      color: #3778ea;
      background-color: rgba(237, 246, 251, 0.3);
    }
  }
`

export const IconWrapper = styled.div`
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 7rem;

  &:after {
    content: '';
    position: absolute;
    border-right: 1px solid #979797;
    top: 25%;
    bottom: 25%;
    left: 50%;
    transform: translate(-50%);
  }
`

export const CopyText = styled.p`
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.875rem;
  color: #787a7d;
  text-align: right;
`
