import styled from 'styled-components'
export const EditableIconStatus = styled.div`
  padding-top: 5px;
  svg.error {
    fill: ${(props) => props.theme.input.error.borderColor};
    opacity: 1;
    cursor: default;
    &:hover {
      // We force fill color with the same 'red' di override a default hover color
      fill: ${(props) => props.theme.input.error.borderColor};
    }
  }
`
export const EditableTextStyled = styled.div`
  & {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    svg {
      width: 18px;
      &:hover {
        opacity: 1;
        transition: all 0.16s ease-out;
      }
    }
    .text-input-icon {
      position: relative;
      top: 2px;
      cursor: pointer;
    }
    input {
      margin-right: 10px;
      font-family: ${({ theme }) => theme.font.family};
      width: 0;
      min-width: 50px;
      max-width: 600px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: black;
      font-size: 0.9rem;
      padding: 0.2rem;

      &[disabled] {
        font-size: 1rem;
        background-color: transparent;
        border: 0;
        font-weight: 600;
        height: 24px;
        padding: 0;
      }
    }
  }
`
