import styled from 'styled-components'

export const PanelImageStyled = styled.img`
  margin: 1em auto;
  max-width: 100%;
`
export const PanelBodyStyled = styled.div`
  text-align: center;
  p {
    max-width: 100%;
    width: 70%;
    margin: 0 auto;
    text-align: center;
  }
`
export const IntegrationLogo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  flex-direction: column;
  gap: 10px;
  label {
    font-size: small;
  }
`
export const IntegrationPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 1em 0;
`
export const BadgeWrapper = styled.div`
  width: 100%;
  img {
    width: 80px;
  }
`
