import styled from 'styled-components'

export const FastAppSurveyWrapperStyled = styled.div`
  width: 500px;
  margin: 1rem auto;
  text-align: center;
`

export const ContainerStyled = styled.div`
  width: 100%;
`

export const BrandLogoStyled = styled.div`
  padding: 0.5rem 1.5rem;
`

export const HeaderStyled = styled.div`
  background-color: rgba(244, 240, 236, 0.5);
  text-align: center;
  padding-top: 1rem;

  h2 {
    color: rgb(99, 115, 129);
    font-weight: 400;
  }
`

export const IconWrapperStyled = styled.div`
  background-color: #fff;
  width: 30rem;
  margin: auto;
  border-radius: 5px 5px 0 0;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const LogoStyled = styled.img`
  height: 2rem;
  margin: 1rem;
`

export const FormStyled = styled.div`
  padding-top: 2rem;
  width: 25rem;
  margin: 0 auto;

  p:first-child {
    margin: 1.5rem 0 1.5rem;
    color: rgb(99, 115, 129);
  }
`

export const ButtonWrapperStyled = styled.div`
  margin: 2rem auto;
  width: 16rem;
`

export const LoaderWrapperStyled = styled.div`
  height: 20rem;
  position: relative;
  div {
    top: 30%;
    color: rgb(99, 115, 129);
  }
`
