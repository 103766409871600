import styled, { css } from 'styled-components'
import { Props } from './index'

export const FormikInputSmartSelectStyled = styled.div<
  Pick<Props, 'secondaryStyles'>
>`
  display: flex;
  flex-direction: column;
  min-width: fit-content;

  ${({ secondaryStyles }) =>
    secondaryStyles
      ? css`
          margin: 0;
          line-height: 19px;
        `
      : css`
          margin: 0.5rem 0 1rem 0;
        `}
  // line-height: 19px;
    label {
    margin-bottom: 5px;
  }
`
