import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
`

const Header = styled.header`
  width: 100%;
  min-height: 35px;
  padding: 0.5rem 2rem;
  margin-bottom: 2rem;
  background-color: #3fb1f3;
  display: flex;
  align-items: center;
`

const Body = styled.div`
  padding: 0 1rem;
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const Title = styled.div`
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
`

const SubTitle = styled.div`
  font-size: 0.8rem;
  text-align: center;
  margin-bottom: 1.5rem;
`

const Logo = styled.img`
  max-width: 90px;
  display: block;
  margin-bottom: 1rem;
`

const Disclaimer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  max-width: 520px;
  color: #919eab;
  font-size: 0.9rem;
`

const InputGroup = styled.div<{
  centered?: boolean
}>`
  margin-bottom: 1rem;
  width: 100%;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`

const InfoSectionWrapper = styled.div`
  padding-left: 1.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    border-right: 1px solid #cfc7c0;
    top: 6%;
    bottom: 6%;
  }
`

const InfoSectionTitle = styled.p`
  color: #888888;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 1.2rem;
`

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`

const IconRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  p {
    margin: 0;
    font-size: 0.9rem;
    color: #41a6dc;
    font-weight: 600;
  }
`

const IconCircle = styled.div`
  background-color: dodgerblue;
  color: #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const StyledAuthorizationPopup = {
  Wrapper,
  Header,
  Body,
  Disclaimer,
  Logo,
  Title,
  SubTitle,
  InputGroup,
  IconCircle,
  IconsWrapper,
  IconRow,
  InfoSectionWrapper,
  InfoSectionTitle,
}

export const FormWrapper = styled.div`
  width: 100%;

  form {
    /*border-right: 1px solid rgba(151, 151, 151, 0.5);*/
    padding-right: 1.5rem;
    flex: 1;
  }

  > * {
    margin-bottom: 1rem;
  }
`

export const FormRow = styled.div`
  display: flex;
`

export const FormInputGroup = styled.div<{
  centered?: boolean
}>`
  margin-bottom: 1rem;
  width: 100%;
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`

export const ActionButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`

export const SubmitButtonWrapper = styled.div`
  width: 290px;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  margin: 0 auto 1rem;
`

export const EditButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 1rem;
`

export const InputWrapper = styled.div<{ isVerified?: boolean }>`
  display: ${({ isVerified }) => (isVerified ? 'block' : 'flex')};
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  div {
    flex: 2;
  }

  button {
    padding: 0;
  }
`
