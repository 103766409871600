import styled, { css } from 'styled-components'
import { IntegrationPanelBodyProps } from '@features/googleAdsAccount/IntegrationPanel/index'

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: center;
  svg {
    font-weight: 600;
    color: ${({ theme }) => theme.color.primary};
  }
`
export const IntegrationPanelStyled = styled.div`
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.color.primary};
`
export const IntegrationPanelWrapper = styled.div`
  padding: 1em 1.5em;
`
export const IntegrationPanelHead = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1em;
  align-items: center;
`
export const PanelHeadRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 1em;
  width: fit-content;
  min-width: 115px;
  button {
    white-space: nowrap;
  }
`

export const PanelHeadRightLink = styled.a`
  font-weight: 600;
  color: ${({ theme }) => theme.color.primary};
  text-decoration: none;
  font-size: 14px;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: underline;
  }
`

export const PanelHeadLeft = styled.div`
  div:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  div:nth-child(2) {
    opacity: 0.6;
    margin-top: 0.5em;
    max-width: 610px;
  }
`
export const AcceptedBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  svg {
    fill: ${({ theme }) => theme.color.green};
  }
`
export const PanelHeadTitle = styled.p`
  font-weight: 600;
`
export const IntegrationPanelFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`
export const IntegrationPanelBody = styled.div<IntegrationPanelBodyProps>`
  padding-top: 1em;
  margin-top: 1em;
  border-top: 1px solid ${({ theme }) => theme.color.primary};

  ${({ $open }) =>
    $open
      ? css`
          opacity: 1;
          transition: max-height 0.15s ease-out, opacity 0.36s ease-out;
          max-height: 500px;
        `
      : css`
          opacity: 0;
          transition: all 0.16s ease-in-out;
          max-height: 0;
          transition: max-height 0.15s ease-in, opacity 0.26s ease-in;
          overflow: hidden;
        `}
`
