import styled from 'styled-components'

export const StepCompletedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0;
  // max-width: 490px;
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  align-content: center; ;
`

export const StepImage = styled.img`
  max-width: 350px;
  align-self: center;
  margin: 0.3rem auto 0.5rem;
`

export const BridgeSettingsWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  // max-width: 640px;
  margin: 0 auto;
`

export const StyledBannerWrapper = styled.div`
  padding: 40px;
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: #f1f9fe;
  align-self: center;
  border-radius: 8px;
  text-align: left;
  align-items: center;
  display: flex;
  flex-direction: row;
`

export const StyledNewFeatureChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 4px;
  background-color: #f58025;
  color: #fff;
  margin: 0;
  max-height: 16px;
  max-width: 89px;
  border-radius: 3px;
`

export const StyledHeading = styled.h2`
  margin-top: 21px;
  margin-bottom: 21px;
  font-weight: 600;
  padding: 0;
  font-size: 18px;
`

export const StyledParagraph = styled.p`
  margin-bottom: 21px;
  font-size: 13px;
  color: #555;
`

export const StyledTipWrapper = styled.div`
  margin-left: 12px;
  margin-top: 240px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  height: 100%;
  max-width: 200px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #3fb1f2;
  background-color: #e9f7fe;
`

export const StyledTipHeading = styled.div`
  display: flex;
  gap: 8px;
  padding: 0;
  margin: 0;
  align-items: start;
  flex-direction: row;
`

export const StyledTipParagraph = styled.p`
  padding: 0;
  margin: 0;
  font-size: 12px;
`
export const StyledPercentage = styled.div`
  margin-top: 12px;
  font-size: 28px;
  font-weight: 600;
  line-height: 28px;
`
