import styled, { css } from 'styled-components'

interface StyledSkeletonProps {
  width: string
  height: string
  marginBottom: string
  marginTop: string
}

// We should rewrite this with a better animation :)
const getAnimation = () => css`
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #dbdbdb;
    animation: skeleton-move 2s infinite;
  }

  @keyframes skeleton-move {
    0% {
      width: 5%;
    }
    100% {
      width: 100%;
    }
  }
`

export const StyledSkeleton = styled.div<StyledSkeletonProps>`
  display: block;
  max-width: 100%;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  background-color: #efefef;
  border-radius: 0.3rem;

  ${getAnimation}
`
