import styled from 'styled-components'

interface DropDownStyledProps {
  isVisible: boolean
  position: { x: number; y: number }
}

interface DropDownItemStyledProps {
  isHighlighted: boolean
  index: number
}

export const DropDownStyled = styled.div<DropDownStyledProps>`
  position: fixed;
  top: ${({ position }) => position.y}px;
  left: ${({ position }) => position.x}px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  border: 1px solid #41a6dc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 1px 3px 6px 0 rgba(25, 25, 25, 0.2);
  overflow-y: auto;
  max-height: 200px;
  min-width: 500px;
  max-width: 60vw;
`

export const DropDownItemStyled = styled.div<DropDownItemStyledProps>`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  cursor: pointer;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? '#3ea2da' : 'transparent'};
  color: ${({ isHighlighted }) => (isHighlighted ? '#fff' : '#000')};
`
