import styled, { css } from 'styled-components'

interface PanelPopupStyledProps {
  position: 'floating' | 'bottom'
}

const getPanelStyle = ({ position }: PanelPopupStyledProps) =>
  position === 'floating'
    ? css`
        bottom: calc(100% + 40px);
        left: 2rem;
        right: 2rem;
      `
    : css`
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
      `

export const PanelPopupStyled = styled.div<PanelPopupStyledProps>`
  position: absolute;
  transform: translateY(100%);
  opacity: 0;
  transition: all 0.16s ease-in;
  z-index: 10;
  ${getPanelStyle}

  &.visible {
    opacity: 1;
    transition: all 0.16s ease-out;
    transform: translateY(0);
  }
`
export const PanelPopupWrapper = styled.div`
  padding: 1rem 1.5rem 2.5rem;
  border-radius: 5px;
  background-color: white;
  border: 1px solid rgb(29 29 29 / 10%);
  box-shadow: 2px 3px 2px 0px rgb(29 29 29 / 5%);
`

export const PanelPopupHeader = styled.div`
  width: 100%;
`
export const PanelPopupButtonClose = styled.button`
  background-color: #fff;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.8rem;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.2rem;
  }

  &:hover {
    color: ${({ theme }) => theme.color.link};
  }
`

export const PanelPopupBody = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`

export const PanelPopupContent = styled.div`
  text-align: left;
  flex: 1;
  margin-top: 1rem;
  padding-right: 3rem;
  font-size: 0.9rem;
`

export const PanelPopupImageWrapper = styled.div`
  text-align: center;
  width: 100px;
  margin: 0 auto;

  img,
  svg {
    width: 100%;
  }
`
