import styled from 'styled-components'

export const BillingDetailsStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
  background-color: #ece8e8;
  border-radius: 6px;

  p {
    margin: 0 1rem;
    font-size: 1rem;
    color: #777b80;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
